<template>
    <span>
        <button
          v-show="show"
          type="button"
          class="btn btn-default ml-1 mr-1"
          :class="showButtonClass"
          :title="showButtonTitle"
          @click="open"
        >
            <slot name="showButton">
                <i class="fa" :class="showButtonIconClass"></i> <span class="hidden-sm-down">{{ showButtonText }}</span>
            </slot>
        </button>

        <app-modal
          v-show="isVisible"
          :title="title"
          @close="close"
          modal-size="sm"
        >
            <template slot="body">
                <div>
                    {{ body }}
                    <slot name="body"></slot>
                </div>
                <div class="alert alert-success" role="alert" v-if="message">
                    {{ message }}
                </div>
                <div class="alert alert-danger" role="alert" v-if="error">
                    <i class="fa fa-exclamation-triangle"></i> {{ error }}
                </div>
            </template>
            <template slot="footer">
                <div class="row">
                    <button type="button" class="btn btn-default" @click="close">
                        {{ $t('eshopSubscriptionPayment.modal.defaults.button.cancel') }}
                    </button>
                    <button class="btn btn-success ml-1 mr-3" @click="confirm(data)">
                        <slot name="confirmButton">
                            <i class="fa fa-check-circle"></i> <span class="hidden-sm-down">{{ confirmButton }}</span>
                        </slot>
                    </button>
                </div>
            </template>
        </app-modal>
    </span>
</template>

<script>
import Modal from '../shared/Modal'

export default {
  name: 'ModalButton',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: Object,
    title: {
      type: String,
      default: function () {
        return this.$t('eshopSubscriptionPayment.modal.defaults.title')
      }
    },
    body: {
      type: String,
      default: function () {
        return this.$t('eshopSubscriptionPayment.modal.defaults.body')
      }
    },
    message: {
      type: String,
      default: function () {
        return this.$t('eshopSubscriptionPayment.modal.defaults.message')
      }
    },
    error: {
      type: String
    },
    showButtonTitle: {
      type: String,
      default: function () {
        return this.$t('eshopSubscriptionPayment.modal.defaults.button.show')
      }
    },
    showButtonText: {
      type: String,
      default: function () {
        return this.$t('eshopSubscriptionPayment.modal.defaults.button.show')
      }
    },
    showButtonClass: {
      type: String,
      default: 'btn-default'
    },
    showButtonIconClass: {
      type: String,
      default: 'fa-edit'
    },
    confirmButton: {
      type: String,
      default: function () {
        return this.$t('eshopSubscriptionPayment.modal.defaults.button.confirm')
      }
    }
  },
  components: {
    appModal: Modal
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    open () {
      this.isVisible = true
    },
    close () {
      this.isVisible = false
      this.$emit('close')
    },
    confirm (data) {
      this.$emit('confirm', data)
    }
  }
}
</script>
