<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-close class="ml-4 float-right" route-name="eshopSubscriptionPayment_list"></app-button-close>
    </template>
    <template slot="body">
      <app-preloader v-if="callingAPI"></app-preloader>
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="m-r-5">
              <app-modal-button
                class="float-right"
                :data="payment"
                :show-button-icon-class="'fa-file-export'"
                :show-button-title="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.button')"
                :show-button-text="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.button')"
                :title="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.title')"
                :body="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.confirmation')"
                :message="modal.message"
                :error="modal.error"
                @confirm="exportPeriodik"
                @close="close"
              ></app-modal-button>
              <app-modal-button
                class="float-right"
                :data="payment"
                :show-button-title="$t('eshopSubscriptionPayment.modal.changePaymentResult.button')"
                :show-button-text="$t('eshopSubscriptionPayment.modal.changePaymentResult.button')"
                :title="$t('eshopSubscriptionPayment.modal.changePaymentResult.title')"
                :body="$t('eshopSubscriptionPayment.modal.changePaymentResult.confirmation')"
                :message="modal.message"
                :error="modal.error"
                @confirm="changePaymentResult"
                @close="close"
              >
              </app-modal-button>
              <app-modal-button
                :show="isRecurring(payment) && isActive(payment) === false"
                class="float-right"
                :data="payment"
                :show-button-icon-class="'fa-redo'"
                :show-button-title="$t('eshopSubscriptionPayment.modal.activatePayment.button')"
                :show-button-text="$t('eshopSubscriptionPayment.modal.activatePayment.button')"
                :title="$t('eshopSubscriptionPayment.modal.activatePayment.title')"
                :body="$t('eshopSubscriptionPayment.modal.activatePayment.confirmation')"
                :message="modal.message"
                :error="modal.error"
                @confirm="activatePayment"
                @close="close"
              ></app-modal-button>
              <app-modal-button
                :show="isRecurring(payment) && isActive(payment)"
                class="float-right"
                :data="payment"
                :show-button-icon-class="'fa-power-off'"
                :show-button-title="$t('eshopSubscriptionPayment.modal.cancelPayment.button')"
                :show-button-text="$t('eshopSubscriptionPayment.modal.cancelPayment.button')"
                :title="$t('eshopSubscriptionPayment.modal.cancelPayment.title')"
                :body="$t('eshopSubscriptionPayment.modal.cancelPayment.confirmation')"
                :message="modal.message"
                :error="modal.error"
                @confirm="deactivatePayment"
                @close="close"
              ></app-modal-button>
              <app-modal-button
                :show="isRecurring(payment) && isActive(payment)"
                class="float-right"
                :data="payment"
                :show-button-icon-class="'fa-calendar'"
                :show-button-title="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.button')"
                :show-button-text="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.button')"
                :title="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.title')"
                :body="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.confirmation')"
                :message="modal.message"
                :error="modal.error"
                @confirm="changeNextPaymentAt"
                @close="close"
              >
                <template v-slot:body>
                  <app-datepicker
                    v-model="payment.nextPaymentAt"
                    :label="'Nasledujuca platba'"
                    disable-form-group
                  >
                  </app-datepicker>
                </template>
              </app-modal-button>
            </div>
          </div>
        </div>
      </section>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-body">
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.variableSymbol') }}</td>
                <td>{{ payment.variableSymbol }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.price') }}</td>
                <td>{{ payment.price / 100 }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.status') }}</td>
                <td>{{ paymentStatusType(payment) }}</td>
              </tr>
              <tr v-if="product">
                <td>{{ $t('eshopSubscriptionPayment.product') }}</td>
                <td>
                  <router-link
                    tag="a"
                    :to="{name: 'eshopSubscriptionProduct_edit', params: { id: product.id }}">
                    {{ product.name }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.order') }}</td>
                <td>
                  <router-link
                    tag="a"
                    :to="{name: 'eshopSubscriptionOrder_detail', params: { id: payment.order }}">
                    {{ payment.order }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.email') }}</td>
                <td>
                  <router-link
                    v-if="payment.orderFull.feUser"
                    tag="a"
                    :to="{name: 'feUser_edit', params: { id: payment.orderFull.feUser }}">
                    {{ payment.orderEmail }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.phone') }}</td>
                <td>{{ payment.orderPhone }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.created_at') }}</td>
                <td>{{ payment.createdAt | prettyDateTime }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.modified_at') }}</td>
                <td>{{ payment.modifiedAt | prettyDateTime }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card card-body">
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.payment') }}</td>
                <td>{{ paymentType.title }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.comfortPaySmsStatus') }}</td>
                <td>
                  {{ payment.comfortPaySmsStatus }}
                  <app-modal-button
                    :show="isRecurring(payment) && isActive(payment) === false"
                    class="float-right"
                    :data="payment"
                    :show-button-icon-class="'fa-redo'"
                    :show-button-title="$t('eshopSubscriptionPayment.modal.activatePayment.button')"
                    :show-button-text="''"
                    :show-button-class="'btn-sm'"
                    :title="$t('eshopSubscriptionPayment.modal.activatePayment.title')"
                    :body="$t('eshopSubscriptionPayment.modal.activatePayment.confirmation')"
                    :message="modal.message"
                    :error="modal.error"
                    @confirm="activatePayment"
                    @close="close"
                  ></app-modal-button>
                  <app-modal-button
                    :show="isRecurring(payment) && isActive(payment)"
                    class="float-right"
                    :data="payment"
                    :show-button-icon-class="'fa-power-off'"
                    :show-button-title="$t('eshopSubscriptionPayment.modal.cancelPayment.button')"
                    :show-button-text="''"
                    :show-button-class="'btn-sm'"
                    :title="$t('eshopSubscriptionPayment.modal.cancelPayment.title')"
                    :body="$t('eshopSubscriptionPayment.modal.cancelPayment.confirmation')"
                    :message="modal.message"
                    :error="modal.error"
                    @confirm="deactivatePayment"
                    @close="close"
                  ></app-modal-button>
                </td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.paymentResult') }}</td>
                <td class="align-middle">
                  {{ paymentResultType.title }}
                  <app-modal-button
                    class="float-right"
                    :data="payment"
                    :show-button-title="$t('eshopSubscriptionPayment.modal.changePaymentResult.button')"
                    :show-button-text="''"
                    :show-button-class="'btn-sm'"
                    :title="$t('eshopSubscriptionPayment.modal.changePaymentResult.title')"
                    :body="$t('eshopSubscriptionPayment.modal.changePaymentResult.confirmation')"
                    :message="modal.message"
                    :error="modal.error"
                    @confirm="changePaymentResult"
                    @close="close"
                  >
                  </app-modal-button>
                </td>
              </tr>
              <tr>
                <td>Zaplatené dňa</td>
                <td>{{ payment.payedAt | prettyDateTime }}</td>
              </tr>
              <tr v-if="payment.nextPaymentAt">
                <td>{{ $t('eshopSubscriptionPayment.nextPaymentAt') }}</td>
                <td>
                  {{ payment.nextPaymentAt | prettyDate }}
                  <app-modal-button
                    :show="isRecurring(payment) && isActive(payment)"
                    class="float-right"
                    :data="payment"
                    :show-button-icon-class="'fa-calendar'"
                    :show-button-title="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.button')"
                    :show-button-text="''"
                    :show-button-class="'btn-sm'"
                    :title="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.title')"
                    :body="$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.confirmation')"
                    :message="modal.message"
                    :error="modal.error"
                    @confirm="changeNextPaymentAt"
                    @close="close"
                  >
                    <template v-slot:body>
                      <br/>
                      <app-datepicker
                        v-model="payment.nextPaymentAt"
                        :label="'Datum následujúcej platby'"
                        disable-form-group
                      >
                      </app-datepicker>
                    </template>
                  </app-modal-button>
                </td>
              </tr>
              </tbody>
            </table>

            <h3>Periodik</h3>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>Export status</td>
                <td>
                  <i class="fas fa-check-circle" v-if="payment.aboPeriodikStatus"></i>
                  <app-modal-button
                    class="float-right"
                    :data="payment"
                    :show-button-icon-class="'fa-file-export'"
                    :show-button-title="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.button')"
                    :show-button-text="''"
                    :show-button-class="'btn-sm'"
                    :title="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.title')"
                    :body="$t('eshopSubscriptionPayment.modal.aboPeriodikExport.confirmation')"
                    :message="modal.message"
                    :error="modal.error"
                    @confirm="exportPeriodik"
                    @close="close"
                  ></app-modal-button>
                </td>
              </tr>
              <tr>
                <td>OrderId</td>
                <td>{{ payment.aboPeriodik.orderId }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionPayment.variableSymbol') }}</td>
                <td>{{ payment.aboPeriodik.variableSymbol }}</td>
              </tr>
              </tbody>
            </table>
            <!-- @todo periodik export queue -->
            <!-- @todo subscriptions k paymentu -->
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import ButtonClose from '../shared/ButtonClose'
import Datepicker from '../form/Datepicker'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import PaymentStatusTypeMixin from '../mixins/valueObject/eshop/PaymentStatusTypeMixin'
import PaymentResultTypeMixin from '../mixins/valueObject/eshop/PaymentResultTypeMixin'
import Preloader from '../preloader/Preloader'
import ModalButton from './ModalButton'
import EshopApi from '../../api/eshop'
import { mapState } from 'vuex'
import NotifyService from '../../services/NotifyService'

const API_NAME = '/payment/subscription'

export default {
  name: 'SubscriptionPayment',
  mixins: [
    PaymentTypeMixin,
    PaymentStatusTypeMixin,
    PaymentResultTypeMixin
  ],
  data () {
    return {
      modal: {
        message: '',
        error: ''
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    product () {
      return this.$store.getters['eshopSubscriptionProduct/productById'](this.payment.orderFull.product)
    },
    payment () {
      return this.$store.getters['eshopSubscriptionPayment/detail']
    },
    paymentType () {
      return this.paymentTypeById(this.payment.orderPayment)
    },
    paymentResultType () {
      return this.paymentResultTypeById(this.payment.paymentResult)
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonClose: ButtonClose,
    appPreloader: Preloader,
    appModalButton: ModalButton,
    appDatepicker: Datepicker
  },
  methods: {
    paymentStatusType (payment) {
      return this.paymentStatusTypeById(payment.status).title
    },
    close () {
      this.modal.message = ''
      this.modal.error = ''
    },
    changePaymentResult (payment) {
      const url = API_NAME + '/change-payment-result'
      this.update(url, payment, this.$t('eshopSubscriptionPayment.modal.changePaymentResult.success'))
    },
    exportPeriodik (payment) {
      const url = API_NAME + '/abo-periodik'
      this.update(url, payment, this.$t('eshopSubscriptionPayment.modal.aboPeriodikExport.success'))
    },
    activatePayment (payment) {
      const url = API_NAME + '/activate-payment'
      this.update(url, payment, this.$t('eshopSubscriptionPayment.modal.activatePayment.success'))
    },
    deactivatePayment (payment) {
      const url = this.getUpdatePaymentUrl(payment) + '/cancel'
      this.update(url, payment, this.$t('eshopSubscriptionPayment.modal.cancelPayment.success'))
    },
    changeNextPaymentAt (payment) {
      const url = this.getUpdatePaymentUrl(payment)
      this.update(url, payment, this.$t('eshopSubscriptionPayment.modal.changeNextPaymentAt.success'))
    },
    update (url, record, success) {
      this.$store.commit('TOGGLE_LOADING', null, { root: true })
      EshopApi().put(url + '/' + record.id, JSON.stringify(record))
        .then(response => {
          if (response.data.error) {
            this.modal.error = response.data.error
          } else {
            this.modal.message = success
            this.$store.commit('eshopSubscriptionPayment/storeDetail', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
          NotifyService.setErrorMessage(error)
        })
        .finally(() => {
          this.$store.commit('TOGGLE_LOADING', null, { root: true })
        })
    },
    getUpdatePaymentUrl (payment) {
      if (this.isComfortPay(payment)) {
        return API_NAME + '/comfortPay'
      }
      return API_NAME + '/sms'
    }
  },
  mounted () {
    this.$store.dispatch('eshopSubscriptionPayment/fetchOne', this.$route.params.id)
    this.$store.dispatch('eshopSubscriptionProduct/fetchAll')
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}

table.history {
  table-layout: fixed;
}

table.history td:nth-child(1), table.history td:nth-child(2) {
  width: 25%;
}
</style>
